<template>
  <div>
    <b-overlay :show="processing"
      :spinner-variant="overlayVariant"
      spinner-small
      rounded
      variant="light"
      opacity="0.6">
      <slot></slot>
    </b-overlay>
  </div>
</template>
<script>
export default {
  props: {
    overlayVariant: {
      type: String,
      default: 'primary'
    },
    processing: {
      type: Boolean,
      default: false
    }
  }
}
</script>
