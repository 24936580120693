
import store from '../store'
import { UserRoles } from './auth.roles'
import { isAuthGuardActive, loginpage } from '../constants/config'
import bus from '../main'

export default (to, from, next) => {
  // Check if form has dirty data and trigger warning modal if true
  if (store.getters.isDirty) {
    // trigger modal message to warn about dirty data
    bus.$emit('popDirtyDataModal', to)

    return false
  }
  /* var me = isAuthGuardActive
  var storeme = store
  var roles = UserRoles
  var requiredpageroles = to.meta.roles */
  // matched is the current route you are going to
  // and all parent routes under it
  if (to.matched.some(record => record.meta.loginRequired)) {
    if (isAuthGuardActive) {
      const str = store // .getters.currentUser
      const user = str.getters.currentUser
      if (user) {
        // This is an initial check to insure that the user is in the proper
        // AzureAD group that is allowed to get into Horizon
        if (!RoleChecker(['All ACHC Users'], user.idTokenClaims.groups)) {
          next('/unauthorized')
        }
        // we need to add our specific permission group checks here that will
        // be checked against what was returned from matched.meta on the route we are going to
        if (to.matched.some(record => record.meta.permission)) {
          var match = to.matched.find(record => record.meta.permission)
          if (!PermissionChecker(match.meta.permission)) {
            next('/unauthorized')
          }
          next()
        }
        next()
      } else {
        // next()
        // redirect the user back to login if their is no masl stored token for them
        next(loginpage + '?to=' + to.path)
      }
      // if we aren't using authguard let the user through to the route
    } else {
      next()
    }
  } else {
    next()
  }
}

function RoleChecker (requiredPageRoles, userADRoles) {
  var retroles
  if (requiredPageRoles.length === 1 && requiredPageRoles[0] === undefined) {
    return userADRoles[0]
  }
  // loop through the required roles
  requiredPageRoles.forEach(RequiredRole => {
    // check if the user has one of these roles in their ad profile
    userADRoles.forEach(ADRole => {
      if (ADRole === RequiredRole) {
        retroles = ADRole
      }
    })
  })

  if (retroles) { return retroles } else {
    // if no matching roles were found then return null
    return null
  }
}

function PermissionChecker (permission) {
  var permissions = JSON.parse(localStorage.getItem('userPermissions')).map(p => p.permissionName)
  return permissions.includes(permission)
}
