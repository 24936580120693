/* import { currentUser } from '../../constants/config'
import { getCurrentUser, getCurrentToken } from '../../utils' */
import {
  AzureclientId,
  Azureauthority,
  adminRoot,
  BeaconapiUrl
} from '../../constants/config'
import { PublicClientApplication } from '@azure/msal-browser'
import Store from '../index'
import axios from 'axios'
import rg4js from 'raygun4js'

import Vue from 'vue'

const state = {
  AzureLocalID: localStorage.getItem('AzureLocalID'),
  EmployeeID: localStorage.getItem('employeeID'),
  token: null,
  loggedin: false,
  loginError: null,
  processing: false,
  /**
   * Configuration object to be passed to MSAL instance on creation.
   * For a full list of MSAL.js configuration parameters, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
   */
  msalConfig: {
    auth: {
      clientId: AzureclientId,
      authority: Azureauthority
      // redirectUri: adminRoot
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
    }
  }
}

const getters = {
  currentUser: state => {
    var msalinstance = new PublicClientApplication(state.msalConfig)
    var activeaccount = msalinstance.getAccountByLocalId(state.AzureLocalID)

    // we have to clear the localid if there is no logged in user
    // this stops this getter from being cached
    if (!activeaccount) {
      localStorage.clear('AzureLocalID')
      state.AzureLocalID = null
    }

    return activeaccount
  },
  processing: state => state.processing,
  loginError: state => state.loginError,
  loggedin: state => state.loggedin,
  getToken: state => state.token
}

const mutations = {
  setUser (state, payload) {
    state.AzureLocalID = payload.account.localAccountId
    localStorage.setItem('AzureLocalID', payload.account.localAccountId)
    state.processing = false
    state.loginError = null
  },
  setloggedin (state) {
    state.loggedin = true
  },
  setLogout (state) {
    state.loggedin = false
    localStorage.clear('AzureLocalID')
    state.AzureLocalID = null
    state.processing = false
    state.loginError = null
    state.token = null
  },
  setProcessing (state, payload) {
    state.processing = payload
    state.loginError = null
  },
  setError (state, payload) {
    state.loginError = payload
    state.currentUser = null
    state.processing = false
  },
  clearError (state) {
    state.loginError = null
  },
  setToken (state, token) {
    state.token = token
  },
  removeToken (state) {
    state.token = null
  }
}

const actions = {
  async acquireToken ({ commit }, scopes = ['user.read'], getIdToken = false) {
    var msalinstance = new PublicClientApplication(state.msalConfig)
    const myUserAccount = msalinstance.getAllAccounts()[0]

    if (!msalinstance) {
      return null
    }
    // Set scopes for token request
    const accessTokenRequest = {
      account: myUserAccount,
      scopes: scopes.scopes
    }
    let tokenResp
    try {
      // 1. Try to acquire token silently
      tokenResp = await msalinstance.acquireTokenSilent(accessTokenRequest)
      commit('setToken', tokenResp)
    } catch (err) {
      // 2. Silent process might have failed so try via popup
      tokenResp = await msalinstance.acquireTokenPopup(accessTokenRequest)
      commit('setToken', tokenResp)
    }

    // Just in case check, probably never triggers
    if (!tokenResp.accessToken) {
      commit('removeToken')
      throw new Error("### accessToken not found in response, that's bad")
    }
    if (scopes.getidtoken) {
      return tokenResp.idToken
    } else {
      return tokenResp.accessToken
    }
  },
  async login ({ commit }, to) {
    commit('clearError')
    commit('setProcessing', true)

    // commit('setLogout')

    var msalinstance = new PublicClientApplication(state.msalConfig)
    await msalinstance
      .loginPopup({})
      .then(async result => {
        localStorage.setItem('employeeName', result.account.name)
        var names = result.account.name.split(' ')
        rg4js('setUser', {
          identifier: result.account.username,
          isAnonymous: false,
          email: result.account.username,
          firstName: names[0],
          fullName: result.account.name
        })
        commit('setUser', result)
      })
      .catch(error => {
        // setCurrentUser(null)
        commit('setLogout')
        commit('setError', error.message)
        setTimeout(() => {
          commit('clearError')
        }, 10000)
      })

    // Store employeeID in local storage
    await axios
      .get(`${BeaconapiUrl}/api/authorization/employee/${state.AzureLocalID}`)
      .then(result => {
        localStorage.setItem('employeeID', result.data.employeeID)
        Vue.prototype.$socket.invoke(
          'JoinUserGroup',
          result.data.employeeID.toString()
        )
      })

    // Store employee permissions in local storage
    await axios
      .get(
        `${BeaconapiUrl}/api/authorization/employee-permission/${localStorage.getItem(
          'employeeID'
        )}`
      )
      .then(result => {
        localStorage.setItem('userPermissions', JSON.stringify(result.data))
      })

    try {
      // get the users profile picture and save it as their id
      await Store.dispatch('getPhoto')
    } catch (error) {}

    // set the logged in state so that the login page redirects back to the root page
    commit('setloggedin')
    Vue.prototype.$router.push(to)
  },
  async signOut ({ commit }) {
    var msalinstance = new PublicClientApplication(state.msalConfig)

    await msalinstance
      .logout()
      .then(() => {
        Vue.prototype.$socket.invoke(
          'LeaveUserGroup',
          localStorage.getItem('employeeID')
        )

        rg4js('setUser', {
          isAnonymous: false
        })

        commit('setLogout')
      })
      .catch(error => {
        console.error(error)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
