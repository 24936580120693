import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthGuard from './utils/auth.guard'
import { adminRoot } from './constants/config' //   /app
import { UserRoles } from './utils/auth.roles'
import bus from './main'
import rg4js from 'raygun4js'

Vue.use(VueRouter)
var roles = UserRoles

const parseProps = r => ({
  serviceID: parseInt(r.params.serviceID),
  programID: parseInt(r.params.programID),
  employeeID: parseInt(r.params.employeeID),
  companyID: parseInt(r.params.companyID)
})
const routes = [
  {
    path: '/',
    // component: () => import(/* webpackChunkName: 'home' */ './views/home'),
    redirect: adminRoot,
    meta: { loginRequired: true }
  },
  {
    path: adminRoot,
    component: () => import(/* webpackChunkName: 'appviews' */ './views/app'),
    redirect: adminRoot + '/company/leads',
    // define with Authorization :
    meta: { loginRequired: true },
    children: [
      {
        path: 'history',
        name: 'history',
        redirect: adminRoot + '/company/leads'
      },
      {
        path: 'view-companies',
        name: 'ViewCompanies',
        component: () =>
          import(
            /* webpackChunkName : 'information' */ './views/app/company/ViewCompanies'
          )
      },
      {
        path: 'application-grid',
        name: 'application-grid',
        component: () =>
          import(
            /* webpackChunkName : 'affiliates' */ './views/app/application/Application'
          )
      },
      {
        path: 'affiliates',
        name: 'affiliates',
        component: () =>
          import(
            /* webpackChunkName : 'affiliates' */ './views/app/affiliates/index'
          ),
        children: [
          {
            path: 'affiliates-grid',
            name: 'affiliates-grid',
            component: () =>
              import(
                /* webpackChunkName : 'affiliates' */ './views/app/affiliates/AffiliateGrid'
              ),
            meta: { permission: 'Affiliates' }
          },
          {
            path: 'edit-affiliate/:affiliateID',
            name: 'edit-affiliate',
            props: r => ({ affiliateID: parseInt(r.params.affiliateID) }),
            component: () =>
              import(
                /* webpackChunkName : 'affiliates' */ './views/app/affiliates/AddEditAffiliates'
              ),
            meta: { permission: 'Affiliates' }
          },
          {
            path: 'add-affiliate/',
            name: 'add-affiliate',
            component: () =>
              import(
                /* webpackChunkName : 'affiliates' */ './views/app/affiliates/AddEditAffiliates'
              ),
            meta: { permission: 'Affiliates' }
          },
          {
            path: 'affiliate/:affiliateID/contact',
            name: 'AffiliateContact',
            props: true,
            component: () =>
              import(
                /* webpackChunkName : 'affiliates' */ './views/app/affiliates/AffiliateContact'
              ),
            meta: { permission: 'Affiliates' }
          },
          {
            path: 'affiliate/:affiliateID/phone',
            name: 'AffiliatePhone',
            props: true,
            component: () =>
              import(
                /* webpackChunkName : 'affiliates' */ './views/app/affiliates/AffiliatePhoneGrid'
              ),
            meta: { permission: 'Affiliates' }
          }
        ]
      },
      {
        path: 'company',
        component: () =>
          import(/* webpackChunkName: 'pages' */ './views/app/company/index'),
        redirect: adminRoot + '/company/addcompany',
        children: [
          {
            path: 'old-advanced-search',
            name: 'OldAdvancedSearch',
            component: () => import('./views/app/company/OldAdvancedSearch')
          },
          {
            path: 'old-advanced-search/:searchTerm',
            name: 'OldAdvancedSearchTerm',
            props: r => ({ searchTerm: r.params.searchTerm }),
            component: () => import('./views/app/company/OldAdvancedSearch')
          },
          {
            path: 'advanced-search',
            name: 'AdvancedSearch',
            component: () => import('./views/app/company/AdvancedSearch')
          },
          {
            path: 'advanced-search/:searchTerm',
            name: 'AdvancedSearchTerm',
            props: r => ({ searchTerm: r.params.searchTerm }),
            component: () => import('./views/app/company/AdvancedSearch')
          },
          {
            path: ':companyID/contact',
            name: 'CompanyContact',
            props: r => ({ companyID: Number(r.params.companyID) }),
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/company/CompanyContact'
              )
          },
          {
            path: ':companyID/phone',
            name: 'companyPhoneGrid',
            props: true,
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/company/CompanyPhoneGrid'
              )
          },
          {
            path: 'addcompany',
            name: 'AddCompany',
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/company/AddEditCompany'
              )
          },
          {
            path: ':companyID/edit-company',
            name: 'EditCompany',
            props: parseProps,
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/company/AddEditCompany'
              )
          },
          {
            path: ':companyID/address',
            name: 'CompanyAddresses',
            props: r => ({ companyID: Number(r.params.companyID) }),
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/company/CompanyAddressGrid'
              )
          },
          {
            path: 'leads',
            name: 'leads',
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/company/LeadsDashboard'
              ),
            props: true
            // Commenting this out to be used in the future when the 'home' URL is no longer Leads Dashboard for everyone
            // meta: { permission: 'Leads Dashboard' }
          },
          // application path
          // {
          //   path: 'application',
          //   name: 'application',
          //   component: () =>
          //     import(/* webpackChunkName : 'information' */ './views/app/application/Application'),
          //   props: true
          // },
          // end
          {
            path: ':companyID/leadQuote',
            name: 'leadQuote',
            props: r => ({ companyID: Number(r.params.companyID) }),
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/company/LeadQuote'
              )
          },
          {
            path: ':companyID/activity',
            props: r => ({ companyID: Number(r.params.companyID) }),
            name: 'activities',
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/company/activities/ActivityGrid'
              )
          },
          // There are now two follow up grid routes because one is for the company context
          {
            path: ':companyID/follow-up',
            props: r => ({ companyID: Number(r.params.companyID) }),
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/company/follow-ups/FollowUpGrid'
              )
          },
          // While this one is for the leads dashboard past due follow ups card click
          {
            path: 'follow-up',
            props: true,
            name: 'followUps',
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/company/follow-ups/FollowUpGrid'
              )
          },
          {
            path: ':companyID/work-flow-step-history',
            props: r => ({ companyID: Number(r.params.companyID) }),
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/company/WorkFlowStepHistoryGrid'
              )
          },
          {
            path: ':companyID/duplicate',
            props: r => ({ companyID: Number(r.params.companyID) }),
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/company/CompanyDuplicateComparison'
              )
          },
          {
            path: ':companyID/user-logins',
            props: r => ({ companyID: Number(r.params.companyID) }),
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/admin/userLogins/UserLoginsGrid'
              )
          },
          {
            path: ':companyID/affiliate',
            props: r => ({ companyID: Number(r.params.companyID) }),
            component: () =>
              import(
                /* webpackChunkName : 'information' */ './views/app/company/CompanyAffiliateGrid'
              )
          },
          {
            path: ':companyID/change-request',
            props: r => ({ companyID: Number(r.params.companyID) }),
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/company/ChangeRequestGrid'
              )
          }
        ]
      },
      {
        path: 'admin',
        component: () =>
          import(/* webpackChunkName: 'pages' */ './views/app/admin/'),
        redirect: adminRoot + '/admin/index',
        meta: { loginRequired: true },
        children: [
          {
            path: 'services',
            name: 'servicesTable',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/services/ServicesGrid'
              ),
            meta: { permission: 'Services' }
          },
          {
            path: 'programs',
            name: 'programsTable',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/programs/ProgramsGrid'
              ),
            meta: { permission: 'Programs' }
          },
          {
            path: 'payments',
            name: 'paymentsTable',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/accounting/PaymentsGrid'
              )
            // meta: { permission: 'Programs' }
          },
          {
            path: 'email-templates',
            name: 'emailTemplatesTable',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/emailTemplates/EmailTemplatesGrid'
              ),
            meta: { permission: 'Email Templates' }
          },
          {
            path: 'edit-email-template/:emailTemplateID',
            name: 'editEmailTemplate',
            props: r => ({
              emailTemplateID: parseInt(r.params.emailTemplateID)
            }),
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/emailTemplates/EditEmailTemplate'
              ),
            meta: { permission: 'Email Templates' }
          },
          {
            path: 'quick-add',
            name: 'quick-add',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/company/QuickAdd'
              )
          },
          {
            path: 'Employees',
            name: 'employees',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/employees/EmployeesGrid'
              ),
            meta: { permission: 'Employee Admin' }
          },
          {
            path: 'employees/manage-employees',
            name: 'manage-employees',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/employees/EmployeesGrid'
              ),
            meta: { permission: 'Employee Admin' }
          },
          {
            path: 'employees/permissions',
            name: 'permissions',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/employees/Permissions'
              ),
            meta: { permission: 'Employee Admin' }
          },
          {
            path: 'employees/roles',
            name: 'roles',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/employees/Roles'
              ),
            meta: { permission: 'Employee Admin' }
          },
          {
            path: 'edit-service/:serviceID',
            name: 'editservice',
            props: r => ({ serviceID: parseInt(r.params.serviceID) }),
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/services/AddEditService'
              ),
            meta: { permission: 'Services' }
          },
          {
            path: 'add-service/',
            name: 'addservice',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/services/AddEditService'
              ),
            meta: { permission: 'Services' }
          },
          {
            path: 'edit-program/:programID',
            name: 'editprogram',
            props: r => ({ programID: parseInt(r.params.programID) }),
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/programs/AddEditProgram'
              ),
            meta: { permission: 'Programs' }
          },
          {
            path: 'add-program/',
            name: 'addprogram',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/programs/AddEditProgram'
              ),
            meta: { permission: 'Programs' }
          },
          {
            path:
              'Employees/Manage-Employees/employee-roles-and-permissions/:employeeID',
            name: 'employee-roles-and-permissions',
            props: r => ({ employeeID: parseInt(r.params.employeeID) }),
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/employees/EmployeeRolesAndPermissions'
              ),
            meta: { permission: 'Employee Admin' }
          },
          {
            path: 'request-for-proposal',
            name: 'requestForProposalsTable',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/request-for-proposals/RequestForProposalGrid'
              ),
            meta: { permission: 'Sales RFP' }
          },
          {
            path: 'edit-request-for-proposal/:requestForProposalID',
            name: 'EditRequestForProposal',
            props: p => ({
              requestForProposalID: parseInt(p.params.requestForProposalID)
            }),
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/request-for-proposals/AddEditRequestForProposal'
              ),
            meta: { permission: 'Sales RFP' }
          },
          {
            path: 'add-request-for-proposal',
            name: 'addRequestForProposal',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/request-for-proposals/AddEditRequestForProposal'
              ),
            meta: { permission: 'Sales RFP' }
          },
          {
            path: 'round-robin/:roundRobinTypeID',
            name: 'sales-round-robin',
            props: p => ({
              roundRobinTypeID: parseInt(p.params.roundRobinTypeID)
            }),
            component: () =>
              import('./views/app/admin/sales/round-robin/RoundRobinGrid'),
            meta: { permission: 'Sales Round Robin' }
          },
          {
            path: 'edit-round-robin/:roundRobinTypeID/:roundRobinID',
            name: 'edit-sales-round-robin',
            props: p => ({
              roundRobinID: parseInt(p.params.roundRobinID),
              roundRobinTypeID: parseInt(p.params.roundRobinTypeID)
            }),
            component: () =>
              import('./views/app/admin/sales/round-robin/AddEditRoundRobin'),
            meta: { permission: 'Sales Round Robin' }
          },
          {
            path: 'add-round-robin/:roundRobinTypeID',
            name: 'add-sales-round-robin',
            props: r => ({
              roundRobinTypeID: parseInt(r.params.roundRobinTypeID)
            }),
            component: () =>
              import('./views/app/admin/sales/round-robin/AddEditRoundRobin'),
            meta: { permission: 'Sales Round Robin' }
          },
          {
            path: 'user-logins',
            name: 'user-logins',
            component: () =>
              import(
                /* webpackChunkName : 'admin' */ './views/app/admin/userLogins/UserLoginsGrid'
              )
          },
          {
            path: 'application-grid',
            name: 'application-grid',
            component: () =>
              import(
                /* webpackChunkName : 'affiliates' */ './views/app/admin/sales/ApplicationGrid'
              )
          },
          {
            path: 'reports',
            name: 'reports',
            component: () =>
              import(
                /* webpackChunkName : 'affiliates' */ './views/app/admin/sales/ReportsGrid'
              )
          }
        ]
      },
      {
        path: 'user',
        component: () =>
          import(/* webpackChunkName: 'pages' */ './views/app/admin/'),
        redirect: adminRoot + '/user/notifications',
        meta: { loginRequired: true },
        children: [
          {
            name: 'notifications',
            path: 'notifications',
            component: () =>
              import(
                /* webpackChunkName: 'user' */ './views/app/user/Notifications'
              )
          }
        ]
      }
    ]
  },
  {
    path: '/error',
    component: () => import(/* webpackChunkName: 'error' */ './views/Error')
  },
  {
    path: '/unauthorized',
    component: () =>
      import(/* webpackChunkName: 'error' */ './views/Unauthorized')
  },
  {
    path: '/user',
    component: () => import(/* webpackChunkName: 'user' */ './views/user'),
    redirect: '/user/login',
    children: [
      {
        path: 'login',
        component: () =>
          import(/* webpackChunkName: 'user' */ './views/user/Login')
      },
      {
        path: 'loginAD',
        component: () =>
          import(/* webpackChunkName: 'user' */ './views/user/LoginAD')
      },
      {
        path: 'register',
        component: () =>
          import(/* webpackChunkName: 'user' */ './views/user/Register')
      },
      {
        path: 'forgot-password',
        component: () =>
          import(/* webpackChunkName: 'user' */ './views/user/ForgotPassword')
      },
      {
        path: 'reset-password',
        component: () =>
          import(/* webpackChunkName: 'user' */ './views/user/ResetPassword')
      }
    ]
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: 'error' */ './views/Error')
  }
]

const router = new VueRouter({
  linkActiveClass: 'active',
  routes,
  mode: 'history'
})
router.beforeEach(AuthGuard)

router.afterEach((to, from, next) => {
  rg4js('trackEvent', { type: 'pageView', path: to.fullPath.toLowerCase() })
  bus.$emit('refreshhistory', to)

  bus.$emit('setcompanycontext', to.fullPath.toLowerCase())
  bus.$emit('setaffiliatecontext', to.fullPath.toLowerCase())

  bus.$emit('clearmessages')
})

export default router
