import { UserRoles } from '../utils/auth.roles'
import jsonconfig from './config.json'

export const defaultMenuType = 'menu-default' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const adminRoot = '/app'
export const loginpage = '/user/loginAD'
export const searchPath = `${adminRoot}/pages/miscellaneous/search`
export const buyUrl = 'https://1.envato.market/nEyZa'
export const apiUrl = 'https://api.coloredstrategies.com'

// Azure WebAPI
export const BeaconapiUrl = jsonconfig.WebAPIUrl
export const NavigatorUrl = 'https://navigator.achc.org'
export const NavigatorDevUrl = 'https://navigatordev.achc.org'

// Local WebAPI
// export const BeaconapiUrl = 'https://localhost:44308'
// export const NavigatorUrl = 'http://localhost:8081'

export const RouteHistory = 'routehistory'
export const CompanyContext = 'companycontext'

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

export const defaultLocale = 'en'
export const defaultDirection = 'ltr'
export const localeOptions = [
  { id: 'en', name: 'English LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English RTL', direction: 'rtl' }
]

export const firebaseConfig = {
  apiKey: 'AIzaSyDe94G7L-3soXVSpVbsYlB5DfYH2L91aTU',
  authDomain: 'piaf-vue-login.firebaseapp.com',
  databaseURL: 'https://piaf-vue-login.firebaseio.com',
  projectId: 'piaf-vue-login',
  storageBucket: 'piaf-vue-login.appspot.com',
  messagingSenderId: '557576321564',
  appId: '1:557576321564:web:bc2ce73477aff5c2197dd9'
}

export const currentUser = {
  id: 1,
  title: 'Bryan Luisana',
  img: '/assets/img/profiles/1.jpg',
  date: 'Last seen today 15:24',
  role: UserRoles['All ACHC Users']
}

// turn authentication on or off
export const isAuthGuardActive = true

// auth settings for AzureAD
export const AzureclientId = 'e7c9845d-dee0-4c6a-8a5c-ff1345232092'
export const Azureauthority = 'https://login.microsoftonline.com/e0609065-1e49-4f44-8698-13ff2167530a'

export const themeRadiusStorageKey = 'theme_radius'
export const themeSelectedColorStorageKey = 'theme_selected_color'
export const defaultColor = 'light.blueolympic'
export const colors = ['bluenavy', 'blueyale', 'blueolympic', 'greenmoss', 'greenlime', 'purplemonster', 'orangecarrot', 'redruby', 'yellowgranola', 'greysteel']
