const state = {
  isDirty: false
}

const getters = {
  isDirty: state => state.isDirty
}

const mutations = {
  setDirtyData (state, isDirty) {
    state.isDirty = isDirty
  }
}

const actions = {

}

export default {
  state,
  getters,
  mutations,
  actions
}
