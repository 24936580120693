import Vue from 'vue'
import Vuex from 'vuex'

import app from '../main'
import menu from './modules/menu'
import AzureAD from './modules/AzureAD'
import MSGraph from './modules/MSGraph'
import chat from './modules/chat'
import todo from './modules/todo'
import survey from './modules/survey'
import message from './modules/message'
import dirtyData from './modules/dirtyData'

import { setCurrentLanguage } from '../utils'

import { PublicClientApplication } from '@azure/msal-browser'

Vue.use(Vuex)

export default new Vuex.Store({
  mutations: {
    changeLang (state, payload) {
      app.$i18n.locale = payload
      setCurrentLanguage(payload)
    },
    // we save the entire store state in localstorage
    // this makes it so that a user can close their broswer
    // and still have their state.
    initialiseStore (state) {
      // not used right now but left in place
      // in case we want to initialize any store values on app startup
    }
  },
  actions: {
    setLang ({ commit }, payload) {
      commit('changeLang', payload)
    }
  },
  modules: {
    // template store modules
    menu,
    // user,
    chat,
    todo,
    survey,
    message,
    // azure module
    AzureAD,
    // msgraph api
    MSGraph,
    dirtyData
  }
})
