const state = {
  AlertMessage: '',
  DangerMessage: '',
  WarningMessage: '',
  InfoMessage: '',
  ShowAlertMessage: false,
  ShowDangerMessage: false,
  ShowWarningMessage: false,
  ShowInfoMessage: false,
  Modal: {
    AlertMessage: '',
    DangerMessage: '',
    WarningMessage: '',
    InfoMessage: '',
    ShowAlertMessage: false,
    ShowDangerMessage: false,
    ShowWarningMessage: false,
    ShowInfoMessage: false
  },
  PreserveMessageAfterPush: false
}

const getters = {
  AlertMessage: state => state.AlertMessage,
  DangerMessage: state => state.DangerMessage,
  WarningMessage: state => state.WarningMessage,
  InfoMessage: state => state.InfoMessage,
  ShowAlertMessage: state => state.ShowAlertMessage,
  ShowDangerMessage: state => state.ShowDangerMessage,
  ShowWarningMessage: state => state.ShowWarningMessage,
  ShowInfoMessage: state => state.ShowInfoMessage,

  ModalAlertMessage: state => state.Modal.AlertMessage,
  ModalDangerMessage: state => state.Modal.DangerMessage,
  ModalWarningMessage: state => state.Modal.WarningMessage,
  ModalInfoMessage: state => state.Modal.InfoMessage,
  ShowModalAlertMessage: state => state.Modal.ShowAlertMessage,
  ShowModalDangerMessage: state => state.Modal.ShowDangerMessage,
  ShowModalWarningMessage: state => state.Modal.ShowWarningMessage,
  ShowModalInfoMessage: state => state.Modal.ShowInfoMessage,
  PreserveMessageAfterPush: state => state.PreserveMessageAfterPush
}

const mutations = {
  setDangerMessage (state, message) {
    mutations.hideAllMessages(state)
    state.DangerMessage = message
    state.ShowDangerMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  appendDangerMessage (state, message) {
    mutations.hideAllMessages(state)
    if (state.DangerMessage === '') {
      state.DangerMessage = message
    } else {
      state.DangerMessage = state.DangerMessage + '; ' + message
    }

    state.ShowDangerMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  setAlertMessage (state, message) {
    mutations.hideAllMessages(state)
    state.AlertMessage = message
    state.ShowAlertMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  appendAlertMessage (state, message) {
    mutations.hideAllMessages(state)
    if (state.AlertMessage === '') {
      state.AlertMessage = message
    } else {
      state.AlertMessage = state.AlertMessage + '; ' + message
    }

    state.ShowAlertMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  setWarningMessage (state, message) {
    mutations.hideAllMessages(state)
    state.WarningMessage = message
    state.ShowWarningMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  appendWarningMessage (state, message) {
    mutations.hideAllMessages(state)
    if (state.WarningMessage === '') {
      state.WarningMessage = message
    } else {
      state.WarningMessage = state.WarningMessage + '; ' + message
    }

    state.ShowWarningMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  setInfoMessage (state, message) {
    mutations.hideAllMessages(state)
    state.InfoMessage = message
    state.ShowInfoMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  appendInfoMessage (state, message) {
    mutations.hideAllMessages(state)
    if (state.InfoMessage === '') {
      state.InfoMessage = message
    } else {
      state.InfoMessage = state.InfoMessage + '; ' + message
    }

    state.ShowInfoMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },

  setModalDangerMessage (state, message) {
    mutations.hideAllModalMessages(state)
    state.Modal.DangerMessage = message
    state.Modal.ShowDangerMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  appendModalDangerMessage (state, message) {
    mutations.hideAllModalMessages(state)
    if (state.Modal.DangerMessage === '') {
      state.Modal.DangerMessage = message
    } else {
      state.Modal.DangerMessage = state.Modal.DangerMessage + '; ' + message
    }

    state.Modal.ShowDangerMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  setModalAlertMessage (state, message) {
    mutations.hideAllModalMessages(state)
    state.Modal.AlertMessage = message
    state.Modal.ShowAlertMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  appendModalAlertMessage (state, message) {
    mutations.hideAllModalMessages(state)
    if (state.Modal.AlertMessage === '') {
      state.Modal.AlertMessage = message
    } else {
      state.Modal.AlertMessage = state.Modal.AlertMessage + '; ' + message
    }

    state.Modal.ShowAlertMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  setModalWarningMessage (state, message) {
    mutations.hideAllModalMessages(state)
    state.Modal.WarningMessage = message
    state.Modal.ShowWarningMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  appendModalWarningMessage (state, message) {
    mutations.hideAllModalMessages(state)
    if (state.Modal.WarningMessage === '') {
      state.Modal.WarningMessage = message
    } else {
      state.Modal.WarningMessage = state.Modal.WarningMessage + '; ' + message
    }

    state.Modal.ShowWarningMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  setModalInfoMessage (state, message) {
    mutations.hideAllModalMessages(state)
    state.Modal.InfoMessage = message
    state.Modal.ShowInfoMessage = true
    state.Modal.ShowDangerMessage = false
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  appendModalInfoMessage (state, message) {
    mutations.hideAllModalMessages(state)
    if (state.Modal.InfoMessage === '') {
      state.Modal.InfoMessage = message
    } else {
      state.Modal.InfoMessage = state.Modal.InfoMessage + '; ' + message
    }

    state.Modal.ShowInfoMessage = true
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },

  setShowDangerMessage (state, value) {
    state.ShowDangerMessage = value
  },
  setShowAlertMessage (state, value) {
    state.ShowAlertMessage = value
  },
  setShowWarningMessage (state, value) {
    state.ShowWarningMessage = value
  },
  setShowInfoMessage (state, value) {
    state.ShowInfoMessage = value
  },

  setShowModalDangerMessage (state, value) {
    state.Modal.ShowDangerMessage = value
  },
  setShowModalAlertMessage (state, value) {
    state.Modal.ShowAlertMessage = value
  },
  setShowModalWarningMessage (state, value) {
    state.Modal.ShowWarningMessage = value
  },
  setShowModalInfoMessage (state, value) {
    state.Modal.ShowInfoMessage = value
  },
  hideAllMessages (state) {
    state.ShowAlertMessage = false
    state.ShowInfoMessage = false
    state.ShowWarningMessage = false
    state.ShowDangerMessage = false
  },
  hideAllModalMessages (state) {
    state.Modal.ShowAlertMessage = false
    state.Modal.ShowInfoMessage = false
    state.Modal.ShowWarningMessage = false
    state.Modal.ShowDangerMessage = false
  },
  setPreserveMessageAfterPush (state, value) {
    state.PreserveMessageAfterPush = value
  }
}

export default {
  state,
  getters,
  mutations
}
