import Vue from 'vue'
import App from './App'
import rg4js from 'raygun4js'
import axios from 'axios'

// BootstrapVue add
import BootstrapVue from 'bootstrap-vue'
// Router & Store add
import router from './router'
import store from './store'
// Multi Language Add
import en from './locales/en.json'
import es from './locales/es.json'
import VueI18n from 'vue-i18n'

// Notification Component Add
import Notifications from './components/Common/Notification'
// ApiButtonOverlay Component Add
import ApiButtonOverlay from './components/Common/ApiButtonOverlay'
// RefreshButton Component Add
import RefreshButton from './components/Common/RefreshButton'
// Colxx Component Add
import Colxx from './components/Common/Colxx'
// Perfect Scrollbar Add
import vuePerfectScrollbar from 'vue-perfect-scrollbar'
import contentmenu from 'v-contextmenu'
import VueLineClamp from 'vue-line-clamp'
import VueScrollTo from 'vue-scrollto'
import _ from 'lodash'
import VueMask from 'v-mask'

import { getCurrentLanguage } from './utils'

import VueSignalR from '@latelier/vue-signalr'

import { BeaconapiUrl } from './constants/config'

Vue.use(BootstrapVue)
Vue.use(VueI18n)
const messages = { en: en, es: es }
const locale = getCurrentLanguage()
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'en',
  messages,
  // If you're annoyed with the value of key/keypath translation console warnings
  // then set this to True
  silentTranslationWarn: true
})
Vue.use(VueMask)
Vue.use(Notifications)
Vue.use(require('vue-shortkey'))
Vue.use(contentmenu)
Vue.use(VueScrollTo)
Vue.use(VueLineClamp, {
  importCss: true
})
Vue.use(VueSignalR, `${BeaconapiUrl}/notification-hub`)
Vue.component('api-button-overlay', ApiButtonOverlay)
Vue.component('b-refresh-button', RefreshButton)
Vue.component('b-colxx', Colxx)
Vue.component('vue-perfect-scrollbar', vuePerfectScrollbar)

// try {
//   var tokenKey = Object.keys(localStorage).find((x) => x.includes('idtoken'))
//   var token = JSON.parse(localStorage.getItem(tokenKey)).secret
//   axios.defaults.headers.common.Authorization = 'Bearer ' + token
// } catch {
//   console.log('Failed token stuff :(')
// }

axios.interceptors.request.use(
  async request => {
    // Find token in localstorage
    // var tokenKey = Object.keys(localStorage).find((x) => x.includes('idtoken'))
    // var token = JSON.parse(localStorage.getItem(tokenKey)).secret
    // debugger
    const getIdToken = true
    /// var tokenrespon = await store.dispatch('acquireToken', ['user.read'], true)
    var tokenrespon = await store.dispatch('acquireToken', {
      scopes: ['user.read'],
      getidtoken: true
    })
    /// var token = tokenrespon.idToken

    // console.log(token)
    request.headers.Authorization = `Bearer ${tokenrespon}`

    return request
  },
  error => {
    return Promise.reject(error)
  }
)
// use lodash globally
Object.defineProperty(Vue.prototype, '_', { value: _ })

Vue.config.productionTip = false

// azure ad msal config setup
Vue.config.$msalInstance = {}

Vue.config.errorHandler = function (err, vm, info) {
  rg4js('send', {
    error: err,
    customData: [{ info: info }]
  })
}

export default new Vue({
  i18n,
  router,
  store,
  beforeCreate () {
    this.$store.commit('initialiseStore')
  },
  created () {
    this.$socket.start({
      log: true // Active only in development for debugging.
    })

    var employeeID = localStorage.getItem('employeeID')
    if (employeeID !== null && employeeID !== '') {
      this.$socket.invoke('JoinUserGroup', employeeID.toString())
    }
  },
  beforeDestroy () {
    var employeeID = localStorage.getItem('employeeID')
    if (employeeID !== null && employeeID !== '') {
      this.$socket.invoke('LeaveUserGroup', employeeID.toString())
    }

    this.$socket.stop()
  },
  sockets: {
    RecentNotifications () {},
    JoinUserGroup () {},
    LeaveUserGroup () {},
    JoinDepartmentGroup () {},
    LeaveDepartmentGroup () {}
  },
  render: h => h(App)
}).$mount('#app')
