const GRAPH_BASE = 'https://graph.microsoft.com/v1.0'
const GRAPH_SCOPES = ['user.read', 'user.readbasic.all']

//
// Get details of user, and return as JSON
// https://docs.microsoft.com/en-us/graph/api/user-get?view=graph-rest-1.0&tabs=http#response-1
//
const state = {}

const actions = {
  async getSelf () {
    const resp = await this.actions.callGraph('/me')
    if (resp) {
      const data = await resp.json()
      return data
    }
  },

  //
  // Get user's photo and return as a blob object URL
  // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
  //
  async getPhoto () {
    // check to see if user has a profile pic
    const resp = await this.dispatch('callGraph', '/me/photos') // /48x48/$value
    if (resp) {
      // const blob = await resp.blob() // resp.blob()
      // get the smaller sized version of the profile pi
      const blobresp = await this.dispatch(
        'callGraph',
        '/me/photos/48x48/$value'
      )
      const blob = await blobresp.blob()
      var fileReader = new FileReader()

      // onload needed since Google Chrome doesn't support addEventListener for FileReader
      fileReader.onload = function (evt) {
        // Store Data URL in localStorage
        // Read out file contents as a Data URL
        var result = evt.target.result
        try {
          localStorage.setItem('profilepic', result)
        } catch (e) {
          console.log('Storage failed: ' + e)
        }
      }
      // Load blob as Data URL
      // this fires filereader.onload above
      fileReader.readAsDataURL(blob)
    }
  },
  async getEmployeePhoto (context, azureGuid) {
    // check to see if user has a profile pic
    try {
      const resp = await this.dispatch(
        'callGraph',
        `/users/${azureGuid}/photo`
      )
      if (resp) {
        const blobresp = await this.dispatch(
          'callGraph',
          `/users/${azureGuid}/photo/$value`
        )
        const blob = await blobresp.blob()

        const photo = await new Promise(resolve => {
          const fileReader = new FileReader()
          // onload needed since Google Chrome doesn't support addEventListener for FileReader
          fileReader.onload = e => {
            try {
              resolve(e.target.result)
            } catch (e) {
              resolve(undefined)
            }
          }
          fileReader.readAsDataURL(blob)
        })
        return photo
      }
    } catch (e) {
      return undefined
    }
  },
  //
  // Search for users
  // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
  //
  async searchUsers (searchString, max = 50) {
    const resp = await this.actions.callGraph(
      `/users?$filter=startswith(displayName, '${searchString}') or startswith(userPrincipalName, '${searchString}')&$top=${max}`
    )
    if (resp) {
      const data = await resp.json()
      return data
    }
  },

  async callGraph (state, apiPath) {
    // Acquire an access token to call APIs (like Graph)
    // Safe to call repeatedly as MSAL caches tokens locally
    // accessToken = await msalinstance.acquireTokenSilent(GRAPH_SCOPES)
    const tokenResponse = this.getters.getToken
    let token
    if (tokenResponse && tokenResponse.expiresOn - new Date() > 10000) {
      token = tokenResponse.accessToken
    } else {
      token = await this.dispatch('acquireToken', GRAPH_SCOPES)
    }
    // var token = await AzureAD.actions.acquireToken(GRAPH_SCOPES)

    const resp = await fetch(`${GRAPH_BASE}${apiPath}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    if (!resp.ok) {
      if (resp.status === 401) {
        this.commit('removeToken')
      }
      throw new Error(
        `Call to ${GRAPH_BASE}${apiPath} failed: ${resp.statusText}`
      )
    }

    return resp
  }
}

export default {
  state,
  actions
}
